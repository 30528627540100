import { BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import React from "react";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["Cardano", "IPFS", "Milkomeda"];

const HeadingAnimation = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="intro-heading">
      <span>Your gateway to&nbsp;</span>
      <TextTransition springConfig={presets.default}>
        {TEXTS[index % TEXTS.length]}
      </TextTransition>
      <style jsx>{`
        .intro-heading {
          display: flex;
          font-size: 50px;
          font-weight: 500;
          margin-top: 64px;
          color: ${COLOR.TEXT_WHITE};
          /* text-align: center; */
        }

        @media screen and (max-width: ${BREAKPOINT.SM}px) {
          .intro-heading {
            /* hack,  TextTransition doesn't work if it is in flex div wrapped to multiple lines*/
            display: block;
          }
        }

        @media screen and (max-width: ${BREAKPOINT.XS}px) {
          .intro-heading {
            font-size: ${FONT_SIZE.XXL};
          }
        }
      `}</style>
    </div>
  );
};

export default HeadingAnimation;
